<template>
    <div>
        <base-header class="pb-9">
        </base-header>
        <div class="container-fluid pb-6 header-body">
            <div class="row mt--7">
                <div class="col-xl-3 col-md-6">
                    <stats-card :title="tt('to_do')" icon="fa fa-list-ul" sub-title="68283" type="yellow">
                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-bullseye"></i> 30 {{ tt('item') }}</span>
                            <span class="text-nowrap">{{ tt('your_target') }}</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-md-6">
                    <stats-card :title="tt('cataloged')" icon="fa fa-cog" sub-title="0" type="warning">
                        <template slot="footer">
                            <span class="text-danger mr-2"><i class="fa fa-arrow-down"></i> 0%</span>
                            <span class="text-nowrap">{{ tt('under_target') }}</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-md-6">
                    <stats-card :title="tt('rejected')" icon="fa fa-times" sub-title="0" type="danger">
                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-smile"></i> 0.0%</span>
                            <span class="text-nowrap">{{ tt('good_job') }}</span>
                        </template>
                    </stats-card>

                </div>
                <div class="col-xl-3 col-md-6">
                    <stats-card :title="tt('approved')" icon="fa fa-check" sub-title="0" type="info">
                        <template slot="footer">
                            <span class="text-danger mr-2"><i class="fa fa-frown"></i> 0.0%%</span>
                            <span class="text-nowrap">{{ tt('not_good') }}</span>
                        </template>
                    </stats-card>
                </div>
            </div>

            <div class="row mt--3">
                <div class="col-xl-3 col-md-6">
                    <stats-card :title="tt('processing')" icon="fa fa-paper-plane" sub-title="3" type="orange">
                        <template slot="footer">
                            <span class="text-nowrap">{{ tt('your_target') }}</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-md-6">
                    <stats-card :title="tt('rejected')" icon="fa fa-times" sub-title="0" type="danger">
                        <template slot="footer">
                            <span class="text-nowrap">{{ tt('rejected_mcr') }}</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-md-6">
                    <stats-card :title="tt('approved')"
                            icon="fa fa-check"
                            sub-title="0"
                            type="info">

                        <template slot="footer">
                            <span class="text-nowrap">{{ tt('approved_mcr') }}</span>
                        </template>
                    </stats-card>

                </div>
                <div class="col-xl-3 col-md-6">
                    <stats-card :title="tt('total')"
                            icon="ni ni-chart-bar-32"
                            sub-title="0"
                            type="yellow">

                        <template slot="footer">
                            <span class="text-nowrap">{{ tt('total_mcr') }}</span>
                        </template>
                    </stats-card>
                </div>
            </div>

            <div class="card mb-4">
                <div class="card-header">
                    <div class="row">
                        <div class="col-4">
                            <h3 class="mb-0">{{ tt('detail_kpi_cataloger') }}</h3>
                        </div>
                        <div class="col-8 text-right">
                            <a class="btn btn-sm btn-default" href="#/report/kpi-report">{{ tt('detail') }}</a>
                        </div>
                    </div>
                </div>
                <el-table :data="table.data">
                    <el-table-column label="Name" prop="name" width="250">
                    </el-table-column>
                    <el-table-column label="KPI Report">
                        <el-table-column label="Raw" prop="raw" width="120">
                            <template v-slot="{row}">
                                {{row.raw}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Nyc" prop="nyc" width="120">
                            <template v-slot="{row}">
                                {{row.nyc}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Obs" prop="obs" width="120">
                            <template v-slot="{row}">
                                {{row.obs}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Qa" prop="qa" width="120">
                            <template v-slot="{row}">
                                {{row.qa}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Cat" prop="cat" width="120">
                            <template v-slot="{row}">
                                {{row.cat}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Appr" prop="appr" width="120">
                            <template v-slot="{row}">
                                {{row.appr}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Rev" prop="rev" width="120">
                            <template v-slot="{row}">
                                {{row.rev}}
                            </template>
                        </el-table-column>
                        <el-table-column label="New Mcr" prop="new_mcr" width="120">
                            <template v-slot="{row}">
                                {{row.new_mcr}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Ver" prop="ver" width="120">
                            <template v-slot="{row}">
                                {{row.ver}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Sap" prop="sap" width="120">
                            <template v-slot="{row}">
                                {{row.sap}}
                            </template>
                        </el-table-column>
                        <el-table-column label="Coy" prop="coy" width="120">
                            <template v-slot="{row}">
                                {{row.coy}}
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="Total" prop="total" width="150">
                        <template v-slot="{row}">
                            {{row.total}}
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="row">
                <div class="col-4">
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <form action="http://930a92289574.ngrok.io/my-work" method="get">
                                <div class="row align-items-center">
                                    <div class="col-12">
                                        <h6 class="text-uppercase text-muted ls-1 mb-1">{{ tt('monitoring') }}</h6>
                                        <h3 class="mb-0">{{ tt('users_status') }}</h3>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <el-table :data="tableUsersStatus.data" class="table-responsive table-flush"
                                header-row-class-name="thead-light">
                            <el-table-column :label="tt('users')" :prop="tt('users')" min-width="200" sortable>
                                <template v-slot="{row}">
                                    <div class="media align-items-center">
                                        <a class="avatar avatar-sm rounded-circle mr-3">
                                            <img src="img/theme/team-4.jpg">
                                        </a>
                                        <div class="media-body">
                                            <span class="mb-0 text-sm">{{row.name}}</span>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('status')" :prop="tt('status')" min-width="200" sortable>
                                <template v-slot="{row}">
                        <span class="badge badge-dot mr-4" v-if="row.status == 'Online'">
                              <i class="bg-success"></i> {{ row.status }}
                        </span>
                                    <span class="badge badge-dot mr-4" v-else>
                              <i class="bg-light"></i> {{ row.status }}
                        </span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <form action="http://930a92289574.ngrok.io/my-work" method="get">
                                <div class="row align-items-center">
                                    <div class="col-12">
                                        <h6 class="text-uppercase text-muted ls-1 mb-1">{{ tt('summary') }}</h6>
                                        <h3 class="mb-0">{{ tt('catalog_status') }}</h3>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <el-table :data="tableCatalogStatus.data" class="table-responsive table-flush"
                                header-row-class-name="thead-light">
                            <el-table-column :label="tt('catalog_status')" :prop="tt('catalog_status')" min-width="200"
                                    sortable>
                                <template v-slot="{row}">
                                    {{row.catalog_status}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('count')" :prop="tt('count')" min-width="200" sortable>
                                <template v-slot="{row}">
                                    {{row.count}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="col-4">
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <form action="http://930a92289574.ngrok.io/my-work" method="get">
                                <div class="row align-items-center">
                                    <div class="col-12">
                                        <h6 class="text-uppercase text-muted ls-1 mb-1">{{ tt('summary') }}</h6>
                                        <h3 class="mb-0">{{ tt('material_catalog_request') }}</h3>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <el-table :data="tableMaterialCatalogRequest.data" class="table-responsive table-flush"
                                header-row-class-name="thead-light" height="950px">
                            <el-table-column :label="tt('status')" :prop="tt('status')" min-width="200" sortable>
                                <template v-slot="{row}">
                                    {{row.status}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('count')" :prop="tt('count')" min-width="200" sortable>
                                <template v-slot="{row}">
                                    {{row.count}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <card header-classes="bg-transparent" type="default">
                        <div class="row align-items-center" slot="header">
                            <div class="col">
                                <h6 class="text-light text-uppercase ls-1 mb-1">{{ tt('overview') }}</h6>
                                <h5 class="h3 text-white mb-0">{{ tt('cataloged_material') }}</h5>
                            </div>
                        </div>
                        <line-chart :chart-data="bigLineChart.chartData" :extra-options="bigLineChart.extraOptions" :height="350" ref="bigChart"></line-chart>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	import BaseProgress from '@/components/BaseProgress';
	import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
	import StatsCard from '@/components/Cards/StatsCard';

	import * as chartConfigs from '@/components/Charts/config';
	import LineChart from '@/components/Charts/LineChart';

	export default {
		data() {
			return {
				bigLineChart: {
					allData: [
						[0, 20, 10, 30, 15, 40, 20, 60, 60],
						[0, 20, 5, 25, 10, 30, 15, 40, 40]
					],
					activeIndex: 0,
					chartData: {
						datasets: [
							{
								label: 'Performance',
								data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
							}
						],
						labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
					},
					extraOptions: chartConfigs.blueChartOptions,
				},
				tableMaterialCatalogRequest: {
					data: [
						{
							id: 1,
							status: 'Processing',
							count: '12'
						},
						{
							id: 2,
							status: 'Rejected',
							count: '1'
						},
						{
							id: 3,
							status: 'Approved',
							count: '4'
						},
						{
							id: 4,
							status: 'Stored in SAP',
							count: '1'
						},
						{
							id: 5,
							status: 'Closed',
							count: '1'
						},
					]
				},
				tableCatalogStatus: {
					data: [
						{
							id: 1,
							catalog_status: 'RAW',
							count: '68283'
						},
						{
							id: 2,
							catalog_status: 'NYC',
							count: '0'
						},
						{
							id: 3,
							catalog_status: 'OBS',
							count: '0'
						},
					]
				},
				tableUsersStatus: {
					data: [
						{
							id: 1,
							name: 'Administrator SmartCat',
							status: 'Online'
						},
						{
							id: 2,
							name: 'Abdul Fachruddin',
							status: 'Offline'
						},
						{
							id: 3,
							name: 'Aghnia Siregar',
							status: 'Offline'
						},
					]
				},
				table: {
					data: [
						{
							id: 1,
							name: 'Administrator SmartCat',
							raw: 0,
							nyc: 0,
							obs: 0,
							qa: 0,
							cat: 0,
							appr: 0,
							rev: 0,
							new_mcr: 0,
							ver: 0,
							sap: 0,
							coy: 0,
							total: 0,
						},
						{
							id: 2,
							name: 'Andry Ramadani',
							raw: 0,
							nyc: 0,
							obs: 0,
							qa: 0,
							cat: 0,
							appr: 0,
							rev: 0,
							new_mcr: 0,
							ver: 0,
							sap: 0,
							coy: 0,
							total: 0,
						},
						{
							id: 3,
							name: 'Rizky Samosir',
							raw: 0,
							nyc: 0,
							obs: 0,
							qa: 0,
							cat: 0,
							appr: 0,
							rev: 0,
							new_mcr: 0,
							ver: 0,
							sap: 0,
							coy: 0,
							total: 0,
						},
						{
							id: 4,
							name: 'Mawan Irwansyah',
							raw: 0,
							nyc: 0,
							obs: 0,
							qa: 0,
							cat: 0,
							appr: 0,
							rev: 0,
							new_mcr: 0,
							ver: 0,
							sap: 0,
							coy: 0,
							total: 0,
						},
						{
							id: 5,
							name: 'Muhammad Ihsan',
							raw: 0,
							nyc: 0,
							obs: 0,
							qa: 0,
							cat: 0,
							appr: 0,
							rev: 0,
							new_mcr: 0,
							ver: 0,
							sap: 0,
							coy: 0,
							total: 0,
						},
					]
				},
			}
		},
		components: {
			BaseProgress,
			RouteBreadCrumb,
			StatsCard,
			LineChart
		},
		methods: {
			initBigChart(index) {
				let chartData={
					datasets: [
						{
							label: 'Performance',
							data: this.bigLineChart.allData[index]
						}
					],
					labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
				};
				this.bigLineChart.chartData=chartData;
				this.bigLineChart.activeIndex=index;
			}
		},
		mounted() {
			this.initBigChart(0);
		}
	};
</script>
<style></style>
